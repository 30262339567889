export const PORTFOLIO_FAIL = 'PORTFOLIO_FAIL';
export const PORTFOLIO_START = 'PORTFOLIO_START';

export const PORTFOLIO_SUMMERY_SAGA = 'PORTFOLIO_SUMMERY_SAGA';
export const PORTFOLIO_SUMMERY_SUCCESS = 'PORTFOLIO_SUMMERY_SUCCESS';

export const ASSET_SUMMERY_SAGA = 'ASSET_SUMMERY_SAGA';
export const ASSET_SUMMERY_SUCCESS = 'ASSET_SUMMERY_SUCCESS';

export const PROPERTY_TXNS_SAGA = 'PROPERTY_TXNS_SAGA';
export const PROPERTY_TXNS_SUCCESS = 'PROPERTY_TXNS_SUCCESS';
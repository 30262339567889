export const GET_MARKET_START = 'GET_MARKET_START';
export const GET_MARKET_SUCCESS = 'GET_MARKET_SUCCESS';
export const GET_MARKET_FAIL = 'GET_MARKET_FAIL';
export const GET_MARKET = 'GET_MARKET';
export const CREATE_MARKET = 'CREATE_MARKET';
export const CREATE_MARKET_START = 'CREATE_MARKET_START';
export const CREATE_MARKET_SUCCESS = 'CREATE_MARKET_SUCCESS';
export const CREATE_MARKET_FAIL = 'CREATE_MARKET_FAIL';
export const DELETE_MARKET = 'DELETE_MARKET';
export const DELETE_MARKET_SUCCESS = 'DELETE_MARKET_SUCCESS';
export const EDIT_MARKET = 'EDIT_MARKET';

export const GET_COMPARABLE_PROPERTY_LIST = 'GET_COMPARABLE_PROPERTY_LIST';
export const GET_COMPARABLE_PROPERTY_LIST_START = 'GET_COMPARABLE_PROPERTY_LIST_START';
export const GET_COMPARABLE_PROPERTY_LIST_SUCCESS = 'GET_COMPARABLE_PROPERTY_LIST_SUCCESS';
export const GET_COMPARABLE_PROPERTY_LIST_FAIL = 'GET_COMPARABLE_PROPERTY_LIST_FAIL';
// Get Plaid Link Token

export const GET_PLAID_TOKEN_SAGA = 'GET_PLAID_TOKEN_SAGA';
export const GET_PLAID_TOKEN_START = 'GET_PLAID_TOKEN_START';
export const GET_PLAID_TOKEN_SUCCESS = 'GET_PLAID_TOKEN_SUCCESS';
export const GET_PLAID_TOKEN_FAIL = 'GET_PLAID_TOKEN_FAIL';

// Add ACH Bank Account
export const ADD_ACH_BANK_ACCOUNT_SAGA = 'ADD_ACH_BANK_ACCOUNT_SAGA';
export const ADD_ACH_BANK_ACCOUNT_START = 'ADD_ACH_BANK_ACCOUNT_START';
export const ADD_ACH_BANK_ACCOUNT_SUCCESS = 'ADD_ACH_BANK_ACCOUNT_SUCCESS';
export const ADD_ACH_BANK_ACCOUNT_FAIL = 'ADD_ACH_BANK_ACCOUNT_FAIL';

// Add Wire Bank Account
export const ADD_WIRE_BANK_ACCOUNT_SAGA = 'ADD_WIRE_BANK_ACCOUNT_SAGA';
export const ADD_WIRE_BANK_ACCOUNT_START = 'ADD_WIRE_BANK_ACCOUNT_START';
export const ADD_WIRE_BANK_ACCOUNT_SUCCESS = 'ADD_WIRE_BANK_ACCOUNT_SUCCESS';
export const ADD_WIRE_BANK_ACCOUNT_FAIL = 'ADD_WIRE_BANK_ACCOUNT_FAIL';

// Location for country, state, city

export const GET_LOCATION_SAGA = 'GET_LOCATION_SAGA';
export const GET_LOCATION_START = 'GET_LOCATION_START';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAIL = 'GET_LOCATION_FAIL';
export const GET_SELECTED_LOCATION = 'GET_SELECTED_LOCATION';

// Location for city

export const GET_CITYLOCATION_SAGA = 'GET_CITYLOCATION_SAGA';
export const GET_CITYLOCATION_START = 'GET_CITYLOCATION_START';
export const GET_CITYLOCATION_SUCCESS = 'GET_CITYLOCATION_SUCCESS';
export const GET_CITYLOCATION_FAIL = 'GET_CITYLOCATION_FAIL';
export const GET_SELECTED_CITY = 'GET_SELECTED_CITY';

// Location for District

export const GET_DISTRICTLOCATION_SAGA = 'GET_DISTRICTLOCATION_SAGA';
export const GET_DISTRICTLOCATION_START = 'GET_DISTRICTLOCATION_START';
export const GET_DISTRICTLOCATION_SUCCESS = 'GET_DISTRICTLOCATION_SUCCESS';
export const GET_DISTRICTLOCATION_FAIL = 'GET_DISTRICTLOCATION_FAIL';

// Deposit currency using bank account

export const DEPOSIT_CURRENCY_SAGA = 'DEPOSIT_CURRENCY_SAGA';
export const DEPOSIT_CURRENCY_START = 'DEPOSIT_CURRENCY_START';
export const DEPOSIT_CURRENCY_SUCCESS = 'DEPOSIT_CURRENCY_SUCCESS';
export const DEPOSIT_CURRENCY_FAIL = 'DEPOSIT_CURRENCY_FAIL';

// Withdraw currency using bank account

export const WITHDRAW_CURRENCY_SAGA = 'WITHDRAW_CURRENCY_SAGA';
export const WITHDRAW_CURRENCY_START = 'WITHDRAW_CURRENCY_START';
export const WITHDRAW_CURRENCY_SUCCESS = 'WITHDRAW_CURRENCY_SUCCESS';
export const WITHDRAW_CURRENCY_FAIL = 'WITHDRAW_CURRENCY_FAIL';

// Get All lists of bank accounts

export const GET_LIST_OF_BANK_ACCOUNT_SAGA = 'GET_LIST_OF_BANK_ACCOUNT_SAGA';
export const GET_LIST_OF_BANK_ACCOUNT_START = 'GET_LIST_OF_BANK_ACCOUNT_START';
export const GET_LIST_OF_BANK_ACCOUNT_SUCCESS = 'GET_LIST_OF_BANK_ACCOUNT_SUCCESS';
export const GET_LIST_OF_BANK_ACCOUNT_FAIL = 'GET_LIST_OF_BANK_ACCOUNT_FAIL';

// Get Wallet Balance

export const GET_WALLET_BALANCE_SAGA = 'GET_WALLET_BALANCE_SAGA';
export const GET_WALLET_BALANCE_START = 'GET_WALLET_BALANCE_START';
export const GET_WALLET_BALANCE_SUCCESS = 'GET_WALLET_BALANCE_SUCCESS';
export const GET_WALLET_BALANCE_FAIL = 'GET_WALLET_BALANCE_FAIL';

// Get Transactions list
export const GET_LIST_OF_TRANSACTIONS_SAGA = 'GET_LIST_OF_TRANSACTIONS_SAGA';
export const GET_LIST_OF_TRANSACTIONS_START = 'GET_LIST_OF_TRANSACTIONS_START';
export const GET_LIST_OF_TRANSACTIONS_SUCCESS = 'GET_LIST_OF_TRANSACTIONS_SUCCESS';
export const GET_LIST_OF_TRANSACTIONS_FAIL = 'GET_LIST_OF_TRANSACTIONS_FAIL';

// Get Limits & Service fess

export const GET_SERVICE_FEES_SAGA = 'GET_SERVICE_FEES_SAGA';
export const GET_SERVICE_FEES_START = 'GET_SERVICE_FEES_START';
export const GET_SERVICE_FEES_SUCCESS = 'GET_SERVICE_FEES_SUCCESS';
export const GET_SERVICE_FEES_FAIL = 'GET_SERVICE_FEES_FAIL';

// Delete Card

export const DELETE_CARD_SAGA = 'DELETE_CARD_SAGA';
export const DELETE_CARD_START = 'DELETE_CARD_START';
export const DELETE_CARD_SUCCESS = 'DELETE_CARD_SUCCESS';
export const DELETE_CARD_FAIL = 'DELETE_CARD_FAIL';

// Get Bank Details
export const GET_BANK_DETAILS_SAGA = 'GET_BANK_DETAILS_SAGA';
export const GET_BANK_DETAILS_START = 'GET_BANK_DETAILS_START';
export const GET_BANK_DETAILS_SUCCESS = 'GET_BANK_DETAILS_SUCCESS';
export const GET_BANK_DETAILS_FAIL = 'GET_BANK_DETAILS_FAIL';

// Add Card
export const ADD_CARD_SAGA = 'ADD_CARD_SAGA';
export const ADD_CARD_START = 'ADD_CARD_START';
export const ADD_CARD_SUCCESS = 'ADD_CARD_SUCCESS';
export const ADD_CARD_FAIL = 'ADD_CARD_FAIL';

// Get All lists of cards

export const GET_LIST_OF_CARDS_SAGA = 'GET_LIST_OF_CARDS_SAGA';
export const GET_LIST_OF_CARDS_START = 'GET_LIST_OF_CARDS_START';
export const GET_LIST_OF_CARDS_SUCCESS = 'GET_LIST_OF_CARDS_SUCCESS';
export const GET_LIST_OF_CARDS_FAIL = 'GET_LIST_OF_CARDS_FAIL';

// Get Wire Instruction
export const GET_WIRE_INSTRUCTION = 'GET_WIRE_INSTRUCTION';
export const GET_WIRE_INSTRUCTION_START = 'GET_WIRE_INSTRUCTION_START';
export const GET_WIRE_INSTRUCTION_SUCCESS = 'GET_WIRE_INSTRUCTION_SUCCESS';
export const GET_WIRE_INSTRUCTION_FAIL = 'GET_WIRE_INSTRUCTION_FAIL';
